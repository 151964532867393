import React, { useState } from 'react';
import Calendar from './components/Calendar';
import ScheduleList from './components/ScheduleList';
import MemoList from './components/MemoList';
import './App.css';

function App() {
  const [view, setView] = useState('welcome');

  return (
    <div className="App">
      <header className="App-header">
        <h1>日程助理</h1>
        <nav>
          <button onClick={() => setView('quickRecord')}>
            <i className="icon-quick-record"></i> 快速记
          </button>
          <button onClick={() => setView('viewCalendar')}>
            <i className="icon-calendar"></i> 直接看（日历）
          </button>
          <button onClick={() => setView('viewSchedule')}>
            <i className="icon-calendar"></i> 直接看（列表）
          </button>
        </nav>
      </header>
      <main>
        {view === 'quickRecord' && (
          <div className="screen">
            <MemoList />
          </div>
        )}
        {view === 'viewCalendar' && (
          <div className="screen">
            <Calendar />
          </div>
        )}
        {view === 'viewSchedule' && (
          <div className="screen">
            <ScheduleList />
          </div>
        )}
      </main>
    </div>
  );
}

export default App;