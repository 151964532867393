// src/components/MemoList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaCalendarCheck, FaCalendarTimes } from 'react-icons/fa'; // Import icons
import moment from 'moment-timezone'; // Import moment-timezone
import './MemoList.css'; // Import the CSS file

const MemoList = () => {
  const [memos, setMemos] = useState([]);
  const [newMemoText, setNewMemoText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const apiBaseUrl = window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchMemos = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/notes`);
        setMemos(response.data);
      } catch (error) {
        console.error('Error fetching memos:', error);
      }
    };

    fetchMemos();
  }, [apiBaseUrl]); // Add apiBaseUrl to the dependency array

  const handleAddMemo = async () => {
    if (!newMemoText.trim()) return;

    setIsLoading(true);

    try {
      const response = await axios.post(`${apiBaseUrl}/api/notes`, { text: newMemoText });
      setMemos([response.data, ...memos]);
      setNewMemoText('');
    } catch (error) {
      console.error('Error adding memo:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddMemo();
    }
  };

  return (
    <div>
      <h2></h2>
      <div className="memo-input-container">
        <input
          type="text"
          value={newMemoText}
          onChange={(e) => setNewMemoText(e.target.value)}
          placeholder="请输入..."
          className="memo-input"
          disabled={isLoading}
          onKeyPress={handleKeyPress}
        />
        <button onClick={handleAddMemo} className="memo-button" disabled={isLoading}>
          {isLoading ? <span className="spinner"></span> : '快速记录'}
        </button>
      </div>
      {isLoading && <p className="loading-indicator">正在保存...</p>}
      <ul>
        {memos.map(memo => (
          <li key={memo.id}>
            <div className="memo-content">
              <p>{memo.text}</p>
              <p>{memo.is_schedule ? <FaCalendarCheck title="Scheduled" /> : <FaCalendarTimes title="Not Scheduled" />}</p>
              <p>{moment(memo.created_at).tz(moment.tz.guess()).format('YYYY/MM/DD HH:mm:ss')}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MemoList;
