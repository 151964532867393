import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import moment from 'moment-timezone';

const Calendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const apiBaseUrl = window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL;


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        console.log('Backend URL:', apiBaseUrl); // Log the URL
        const response = await axios.get(`${apiBaseUrl}/api/instances`);
        const eventInstances = response.data.map(instance => {
          // Convert event times to local timezone
          const localStartTime = moment.utc(instance.start_time).tz(moment.tz.guess()).format();
          const localEndTime = moment.utc(instance.end_time).tz(moment.tz.guess()).format();
          return {
            title: instance.schedule_title,
            start: localStartTime,
            end: localEndTime,
            description: instance.schedule_description,
            extendedProps: {
              location: instance.schedule_location,
              scheduleId: instance.schedule,
              instanceId: instance.id
            }
          };
        });
        setEvents(eventInstances);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchEvents();
  }, [apiBaseUrl]); // Add apiBaseUrl to the dependency array

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
  };

  const closeModal = () => {
    setSelectedEvent(null);
  };

  return (
    <div>
      <div className="full-calendar-container">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
        />
      </div>
      {selectedEvent && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h2>{selectedEvent.title}</h2>
            <p><strong>Description:</strong> {selectedEvent.extendedProps.description}</p>
            <p><strong>Location:</strong> {selectedEvent.extendedProps.location}</p>
            <p><strong>Start:</strong> {selectedEvent.start.toLocaleString()}</p>
            <p><strong>End:</strong> {selectedEvent.end.toLocaleString()}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const renderEventContent = (eventInfo) => {
  return (
    <div>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </div>
  );
};

export default Calendar;
