// src/components/ScheduleList.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment-timezone'; // Import moment-timezone
import './ScheduleList.css'; // Import the CSS file


// 引入字体图标库，这里以FontAwesome为例
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faHistory, faSun, faCalendar, faCalendarAlt, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

// 添加所有需要的图标到库中
library.add(faClock, faHistory, faSun, faCalendar, faCalendarAlt, faCalendarCheck);


const ScheduleList = () => {
  const [schedules, setSchedules] = useState([]);
  const [expandedSchedules, setExpandedSchedules] = useState({});

  const apiBaseUrl = window.env?.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL;


  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/schedules`);
        setSchedules(response.data);
      } catch (error) {
        console.error('Error fetching schedules:', error);
      }
    };

    fetchSchedules();
  }, [apiBaseUrl]); // Add apiBaseUrl to the dependency array

  const handleToggle = (scheduleId) => {
    setExpandedSchedules(prevState => ({
      ...prevState,
      [scheduleId]: !prevState[scheduleId]
    }));
  };

  const handleDelete = async (scheduleId) => {
    try {
      await axios.delete(`${apiBaseUrl}/api/schedules/${scheduleId}`);
      setSchedules(schedules.filter(schedule => schedule.id !== scheduleId));
    } catch (error) {
      console.error('Error deleting schedule:', error);
    }
  };

  const getIconByRecurrence = (recurrence) => {
    const iconMap = {
      once: faClock,
      hourly: faHistory,
      daily: faSun,
      weekly: faCalendar,
      monthly: faCalendarAlt,
      yearly: faCalendarCheck,
    };
    return iconMap[recurrence] || faClock; // 默认图标
  };

  return (
    <div>
      <h2></h2>
      <ul className="schedule-list">
        {schedules.map(schedule => (
          <li key={schedule.id}>
            <h3 onClick={() => handleToggle(schedule.id)}>
              {/* 添加图标 */}
              <FontAwesomeIcon icon={getIconByRecurrence(schedule.recurrence_detail.frequency)} />
              {schedule.title}
            </h3>
            <p><strong>详情:</strong> {schedule.description}</p>
            <p><strong>周期:</strong> {schedule.recurrence_desc}</p>
            <p><strong>添加时间:</strong> {moment(schedule.created_at).tz(moment.tz.guess()).format('YYYY/MM/DD HH:mm:ss')}</p>
            <p>{schedule.location}</p>
            {expandedSchedules[schedule.id] && (
              <div className="recurrence-detail">
                <p><strong>Frequency:</strong> {schedule.recurrence_detail.frequency}</p>
                <p><strong>Interval:</strong> {schedule.recurrence_detail.interval}</p>
                <p><strong>Duration:</strong> {schedule.recurrence_detail.duration} minutes</p>
                <p><strong>Start Time:</strong> {moment(schedule.recurrence_detail.start_time).tz(moment.tz.guess()).format('YYYY/MM/DD HH:mm:ss')}</p>
                {schedule.recurrence_detail.end_date && (
                  <p><strong>End Date:</strong> {moment(schedule.recurrence_detail.end_date).tz(moment.tz.guess()).format('YYYY/MM/DD')}</p>
                )}
                <p><strong>Date Range:</strong> {schedule.recurrence_detail.date_range}</p>
              </div>
            )}
            <button onClick={() => handleDelete(schedule.id)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScheduleList;
